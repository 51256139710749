<template>
  <header>
    <h1>
      <a 
        href="#app" 
        v-smooth-scroll
      >
        <img
          alt="Stone"
          src="@/assets/stone_7b.png"
        >
        <div>designisorder</div>
      </a>
    </h1>
    <p>
      creates identities that work…<!-- 
 for every target, every medium, every
      reproduction size and remain functional for a long long time.
 -->
    </p>
    <nav>
      <router-link to="/about">
        About…
      </router-link>
      <router-link to="/contact">
        Like what you see…
      </router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
  },
  methods: {
    totop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
}
</script>

<style scoped lang="scss">
  header {
    background-color: $headerBackgroundColor;
    padding-top: 96px;
    transition: all 0.4s ease-in-out;
    .darkmode & {
      background-color: $invertedHeaderBackgroundColor;
    }
    .extra & {
      padding-top: $cardPadding;
    }
  }
  h1 img {
    position: fixed;
    top: 0;
    left: 50%;
    display: block;
    transform: translateX(-50%);
    z-index: 100;
    transition: all 0.4s ease-in-out;
    .scrolled & {
      transform: translate(-100%, -#{$cardPadding*0.75}) scale(0.5);
      left: 100%;
    }
    .extra & {
      display: none;
    }
  }
  nav a {
    display: block;
    margin: 0.5em 0 0;
    font-family: $boldFontFamily;
  }
</style>