import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store.js'
import VueSmoothScroll from 'vue3-smooth-scroll'

const app = createApp(App).use(router).use(store).use(VueSmoothScroll)
app.mount('#app')

function correctVh() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", correctVh);
