<template>
  <div 
    class="heaven" 
    :class="{ 'darkmode': darkmode(), 'colored': colored(), 'scrolled': scrolled }"
  >
    <router-view @scroll="handleScroll" />
    <Footer />
    <router-view 
      :key="$route.fullPath" 
      name="item"
    />
    <nav class="dashboard">
      <router-link 
        :to="{path: '/', query: { t: $route.query.t}}" 
        class="dio-button dio-close" 
        title="back home"
      >
        home
      </router-link>
      <a 
        href="#appearance"
        class="dio-button dio-invert"
        @click.prevent="theme"
        :title="($store.state.darkmode) ? 'Switch to light mode' : 'Switch to dark mode'"
      >
        invert
      </a>
      <a 
        href="#colormode"
        class="dio-button dio-color"
        @click.prevent="colorize"
        :title="colored ? 'Show brands in grayscale' : 'Show brands in color'"
      >
        color
      </a>
    </nav>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import store from '@/store.js'

export default {
  data() {
    return {
      scrolled: false,
    }
  },
  components: {
    Footer,
  },
  methods: {
    darkmode() {
      return this.$store.state.darkmode;
    },
    colored() {
      return this.$store.state.color;
    },
    theme: function () {
      this.$store.commit("switchTheme");
    },
    colorize: function () {
      this.$store.commit("setColor");
    },
    handleScroll: function() {
      if (window.scrollY < 64) {
        this.scrolled = false
      } else {
        this.scrolled = true
      }
    }
  },
  created() {
    store.commit('getBrands')
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    store.commit('getTheme', params)
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
article {
  max-width: 100vw;
  margin: 0 auto;
  padding: math.div($cardPadding, 2) 0 $cardPadding*2;
  text-align: left;
  p {
    margin-bottom: math.div($cardPadding, 2);
    line-height: 1.5;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* @import '/reset.css/reset.css'; */
@import '@/sass/dashboard';
strong,
b {
  font-family: $boldFontFamily;
}
em,
i {
  font-family: $italicFontFamily;
}
#app {
  text-align: center;
  color: $textColor;
  font-family: $bodyFontFamily;
  font-feature-settings: "onum" 1;
  background-color: $bodyBackgroundColor;
  line-height: 1.4;
  transition: background 1s ease-in-out;
  .darkmode {
    color: $invertedIextColor;
    background-color: $invertedBodyBackgroundColor;
  }
}
@supports (font-variant-numeric: oldstyle-nums) {
  #app {
    font-feature-settings: "onum" 1;
    font-variant-numeric: oldstyle-nums
  }
}
.heaven {
/*   color: #2c3e50; */
  font-family: $bodyFontFamily;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  &:not(.colored) section {
    filter: grayscale(100%);
    cursor: pointer;
  }
}
.dio-container {
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100vw, 1fr));
  align-items: stretch;
  grid-auto-rows: 100vw;
  grid-gap: 0;
  grid-auto-flow: dense;
  overflow: auto;
  > header,
  > section {
    position: relative;
    padding: $cardPadding;
    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      top: 0;
      width: 1px;
      background-image: linear-gradient(
        180deg,
        transparent 0,
        transparent 10%,
        rgba(0, 0, 0, 0.15) 50%,
        transparent 90%
      );
      .darkmode & {
        background-image: linear-gradient(
          180deg,
          transparent 0,
          transparent 10%,
          rgba(255,255,255, 0.25) 50%,
          transparent 90%
        );
      }
    }
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      height: 1px;
      background-image: linear-gradient(
        90deg,
        transparent 0,
        transparent 10%,
        rgba(0, 0, 0, 0.15) 50%,
        transparent 90%
      );
      .darkmode & {
        background-image: linear-gradient(
          90deg,
          transparent 0,
          transparent 10%,
          rgba(255,255,255, 0.25) 50%,
          transparent 90%
        );
      }
    }
    &.col-all {
      grid-column: 1/-1;
      min-height: 0;
      max-height: none;
    }
  }
}
footer {
  flex-shrink: 0;
  position: relative;
  padding: $cardPadding;
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    content: "";
    height: 1px;
    background-image: linear-gradient(
      90deg,
      transparent 0,
      transparent 10%,
      rgba(0, 0, 0, 0.15) 50%,
      transparent 90%
    );
    .darkmode & {
      background-image: linear-gradient(
        90deg,
        transparent 0,
        transparent 10%,
        rgba(255,255,255, 0.5) 50%,
        transparent 90%
      );
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $hFontFamily;
  font-weight: $hFontWeight;
  line-height: 1;
}
h1 {
  font-size: 2em;
  margin: 0 0 $cardPadding;
}
h2 {
  font-family: $boldFontFamily;
  font-size: 1.5em;
  margin: 0 0 0.5em;
}
a {
  text-decoration: none;
  color: inherit;
}

/* give the right aspect ratio to elements */

[style*="--aspectratio"] > :first-child {
  width: 100%;
}
[style*="--aspectratio"] > img {  
  height: auto;
} 
@supports (--custom:property) {
  [style*="--aspectratio"] {
    position: relative;
  }
  [style*="--aspectratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc( 100% / var(--aspectratio, 1) );
  }  
  [style*="--aspectratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
@media only screen and (max-width: $small) and (orientation: landscape) {
  .dio-container {
    grid-template-columns: none;
    grid-auto-rows: auto;
    grid-auto-flow: column;
    grid-auto-columns: 100vh;
    height: 100vh;
    max-height: 100vh;
    > section,
    > header {
      min-height: 100vh;
      min-width: 100vh;
      &.col-2 {
        grid-column: auto / span 2;
        min-width: 200vh;
      }
      &.col-3 {
        grid-column: auto / span 3;
        min-width: 300vh;
      }
      &.row-2,
      &.row-3 {
        grid-row: initial;
      }
    }
  }
}

@include cellHeight(10, dio-container); // eslint-disable-next-line
@media only screen and (min-width: $small) {
  article {
    max-width: 75vw;
  }
  #modal {
    top: math.div($base, 8);
    width: calc(100vw - #{ math.div($base, 4) });
    height: calc((100vw - #{ math.div($base, 4) }) * 0.75);
    max-height: calc((100vw - #{ math.div($base, 4) }) * 0.75);
  }
}
@media only screen and (min-width: $medium) {
  article {
    max-width: 50vw;
  }
  #modal {
    top: #{ math.div($base, 4) };
    width: calc(100vw - #{ math.div($base, 2) });
    height: calc(100vh - #{ math.div($base, 2) });
  }
}
@media only screen and (min-width: $large) {
  article {
    max-width: $small;
  }
  #modal {
    top:  #{ math.div($base, 2) };
    width: calc(100vw -  #{ $base });
    height: calc(100vh - #{ $base });
  }
}
</style>
