<template>
  <div class="dio-container extra 404">
    <Header />
    <section class="col-all">
      <h2>This is a 404 page</h2>
      <p>This is the 404 page which appears when the url not is valid</p>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/Header.vue'

export default {
  name: 'NotFound',
  components: {
    Header
  },
/* 
  data() {
    return {};
  },
  mounted () {
    this.$store.dispatch('brands/openDBChannel').catch(console.error)
  },
 */
}

</script>
