// store.js
import Vuex from 'vuex'
// import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from './db'

export default new Vuex.Store({
  state: {
    brands: [],
    brand: {},
    SvgObjectKey: 0,
    ratios: [],
    darkmode: false,
    color: false,
  },
  mutations: {
    getBrands (state) {
      db.collection('brands')
        .get()
        .then(querySnapshot => {
          state.brands = querySnapshot.docs.map(doc => doc.data())
        })
    },
    getSingleBrand (state, slug) {
      let docRef = db.collection('logos').doc(slug)
      docRef.get().then((doc) => {
        if (doc.exists) {
          state.brand = doc.data()
          state.ratios = doc.data().ratios
        } else {
          // doc.data() will be undefined in this case
          state.brand = false
          console.log("No such document!")
        }
      }).catch((error) => {
          console.log("Error getting document:", error)
      });
    },
    getTheme (state, prm) {
      let t = parseInt(prm.get('t'))
      if(t) {
        state.darkmode = t
      } else {
        let theme = window.matchMedia("(prefers-color-scheme: dark)")
        state.darkmode = theme.matches
      }
    },
    switchTheme(state) {
      state.darkmode = !state.darkmode
      state.SvgObjectKey += 1
    },
    setColor(state) {
      state.color = !state.color
      state.SvgObjectKey += 1
    },
  }
})