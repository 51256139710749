<template>
  <div class="dio-container">
    <Header />
    <Logo
      :key="index"
      v-for="(logo, index) in $store.state.brands"
      :logo="logo"
      :current="index"
    />
  </div>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/Header.vue'
import Logo from "@/components/Logo.vue";

export default {
  name: 'Home',
  components: {
    Header, Logo
  },
/* 
  data() {
    return {};
  },
  mounted () {
    this.$store.dispatch('brands/openDBChannel').catch(console.error)
  },
 */
}

</script>
