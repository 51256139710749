<template>
  <section :style="sectionStyle()">
    <h1 v-html="logo.title" />
    <span :style="bgpos()" />
    <router-link
      :to="{ path: '/logos/' + logo.slug, query: { i: current } }"
      style=""
    />
    <!-- 
    <router-link :to="{ path: '/logos/' + logo.slug, params: { id: logo.id }}">User</router-link>
    -->
  </section>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {};
  },
  props: {
    logo: {
      type: Object,
      default: () => {}
    },
    current: {
      type: Number,
      default: 0
    },
    
  },
  methods: {
    sectionStyle: function() {
      if (this.$store.state.color){
        if (this.$store.state.darkmode) {
          return this.logo.invertedStyles
        } else {
          return this.logo.styles
        }
      } else {
        return false
      }
    },
    bgpos: function() {
      let pos;
      pos = (this.logo.pos) ? this.logo.pos: [10,10];
      return {
        backgroundPosition: "-" + pos[0] + "em -" + pos[1] + "em"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  padding: 0.5em 1em;
  font-family: $boldFontFamily;
  text-align: center;
  font-size: 1.25em;
/* 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 */
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 1s ease-in-out;
  span {
    display: inline-block;
    background-color: yellow;
  }
}
section {
  position: relative;
  background-position: 50%;
  background-size: auto;
  transition: background 1s ease, filter 1s ease-in-out;
  > a {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  > span {
    font-size: 6em; /* thumb size = font-size * 16px */
    position: absolute;
    content: " ";
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    transform: translate(-50%,-50%);
    background: transparent url("/resources/brands-sprites.svg") no-repeat 0 0;
    background-size: 8em;
    transition: opacity 1s ease-in-out;
  }
  &:hover {
    h1 {
      opacity: 1;
    }
    > span {
      opacity: 0;
    }
  }
}
.darkmode section span {
  background-image: url("/resources/brands-sprites-darkmode.svg");
}
</style>
