<template>
  <footer>
    <p>
      <router-link to="/copyright">
        © 1981&#8211;{{ new Date().getFullYear() }}
      </router-link>&nbsp;&nbsp;
      <router-link to="/dio">
        <abbr title="designisorder">dio</abbr>
      </router-link> · 
      <router-link to="/cookie-policy">
        cookie policy
      </router-link>
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
footer {
  padding: $cardPadding;
}
</style>
